/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Layout from "../../components/Layout";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import SEO from "../../components/Seo";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import Masonry from "react-masonry-css";
import LightGallery from "lightgallery/react";
import { FaArrowRight, FaTimes } from "react-icons/fa";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 20,
  },
};
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#___gatsby");

const ProjectSinglePage = ({ data }) => {
  const projectData = data.prismicProjects.data;
  //  const photos = [];
  //  projectData.gallery.map((item, index) => {
  //     const image = getImage(item.image);
  //     photos.push(image);
  //   });
  //   console.log("photos", photos);

  const [modalState, setModalState] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [modalName, setModalName] = useState("");
  const [modalLink, setModalLink] = useState("");

  const openModal = (src) => {
    console.log("src", src);
    //stop bg scrolling when modal open - done in global css above now
    // const pic = getImage(src.image);
    setModalImage(src.image);
    setModalName(src.name);
    setModalLink(src.link);
    // setModalName("yo dude");
    setModalState(true);
  };
  const closeModal = () => {
    setModalState(false);
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <Layout>
      <SEO title={`${projectData.title.text}`} />
      <div
        sx={{
          fontSize: 4,
          px: [3, 2, 1],
          "& .photo-grid": {
            display: "flex",
            marginLeft: "-10px" /* gutter size offset */,
            width: "auto",

            ".photo-grid_column": {
              paddingLeft: "10px" /* gutter size */,
              backgroundClip: "padding-box",
            },
          },
        }}
      >
        <h1>{projectData.title.text}</h1>
        <div
          sx={{
            color: "muted",
            lineHeight: 1.6,
          }}
        >
          <PrismicRichText field={projectData.text.richText} />
        </div>
        <div sx={{ mb: 5 }}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="photo-grid"
            columnClassName="photo-grid_column"
          >
            {projectData.gallery.map((item, index) => {
              const image = getImage(item.image);
              return (
                <a
                  key={`imageRow${index}`}
                  sx={headshotStyles}
                  onClick={(e) => openModal(item)}
                >
                  <div sx={{ marginBottom: "10px", verticalAlign: "top" }}>
                    <GatsbyImage alt={item.image.alt} image={image} />
                    {/* <span>{index}</span> */}
                  </div>
                </a>
              );
            })}
          </Masonry>
        </div>
        <Modal
          isOpen={modalState}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick={true}
          style={modalStyles}
          contentLabel="Image Lightbox Modal"
        >
          <div
            sx={{
              color: "white",
              display: "flex",
              fontSize: "1.25rem",
              justifyContent: "right",
              cursor: "pointer",
              // marginRight: "2em",
              // marginBottom: "0.3em",
            }}
            onClick={closeModal}
          >
            <FaTimes />
          </div>
          <div
            sx={{
              //width: "1000px",
              maxWidth: "90vw",
              maxHeight: "90vh",
              textAlign: "center",
              "& .gatsby-image-wrapper": {
                width: "100%",
                maxWidth: "95vw",
                height: "100%",
                maxHeight: "85vh",
              },
              "& img": {
                //border: "1px solid black",
                objectFit: "contain !important",
              },
            }}
          >
            <GatsbyImage image={getImage(modalImage)} />
          </div>
          <div
            sx={{
              display: "flex",
              pt: 3,
              justifyContent: "center",
              h2: { bg: "white", color: "black", m: 0, p: 2, lineHeight: 1 },
              a: {
                color: "white",
                fontSize: 5,
                ml: 2,
                lineHeight: 1,
                "&:hover, &:focus-within": { color: "muted" },
              },
              svg: { marginTop: "4px" },
            }}
          ></div>
        </Modal>
      </div>
    </Layout>
  );
};

export default withPrismicPreview(ProjectSinglePage);

export const pageQuery = graphql`
  query ProjectSinglePageQuery($uid: String!) {
    prismicProjects(uid: { eq: $uid }) {
      _previewable
      data {
        shortDate: date(formatString: "DD MMM YY")
        fullDate: date(formatString: "DD MMMM, YYYY")
        thumbnail {
          alt
          gatsbyImageData(aspectRatio: 1.77)
        }
        text {
          richText
          text
          raw
        }
        title {
          raw
          richText
          text
        }
        gallery {
          image {
            alt
            gatsbyImageData
            url
          }
        }
      }
    }
  }
`;

const headshotStyles = {
  m: 0,

  figcaption: {
    fontSize: "1.25rem",
  },
  "@media (hover: hover) and (pointer: fine)": {
    position: "relative",
    transition: "all 0.3s ease",
    cursor: "pointer",
    figcaption: {
      fontSize: "1.4rem",
      fontWeight: "400",
      position: "absolute",
      bottom: 2,
      left: 2,
      zIndex: 5,
      bg: "white",
      px: 2,
      py: 2,
      transition: "all 0.3s ease",
      transform: "translate(0,30px)",
      opacity: 0,
    },
    //hover
    "&:hover, &:focus-within": {
      figcaption: {
        transform: "translate(0,0)",
        opacity: 1,
      },
    },
  },
};
